import {useEffect, useRef, useState} from 'react';
import './App.scss';
import axios from 'axios';
import classNames from "classnames";

function App({root_link, root_endpoint, show_button, search_b2b}) {
    const [posts, setPosts] = useState([]);
    const [filterData, setFilterdata] = useState([]);
    const [active, setActive] = useState(false)
    const [valueInput, setValueInput] = useState('');
    const secondRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [b2blink, setB2blink] = useState('');
    const [activeIndex, setActiveIndex] = useState(-1);
    const [search, setSearch] = useState(search_b2b);


    function changeToPolishChar(tekst) {
        if(Object.keys(tekst).length === 0) {
            return ;
        }
        return setPosts(JSON.parse(tekst.replace(/\\u([\d\w]{4})/gi, function (match, grupa1) {
            return String.fromCharCode(parseInt(grupa1, 16));
        })));
    }

    useEffect(() => {
        if (posts.length === 0) {
            axios.get(root_endpoint)
                .then(res => {
                    setPosts(res.data);
                       // console.log(res.data);
                   // changeToPolishChar(res.data);
                })
        }
        setWidth(secondRef.current.offsetWidth);
        setB2blink(root_link);
    }, [posts]);

    const ref = useRef();

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setFilterdata([])
            setActive(false)
            setValueInput('')
        }
    };

    // useEffect(() => {
    //     document.addEventListener('click', handleClickOutside, true);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside, true);
    //     };
    // }, []);

    // useEffect(() => {
    //     const handleKeyPressEvent = (event) => {
    //         if (event.key === 'Enter' && activeIndex !== -1) {
    //             const selectedItem = filterData[activeIndex];
    //             if (selectedItem) {
    //                 window.location.href = selectedItem.url;
    //             }
    //         } else if (event.key === 'Escape') {
    //             setActiveIndex(-1); // Resetuj aktywny indeks
    //             resetInput();
    //         }
    //         setWidth(secondRef.current.offsetWidth);
    //     };
    //     document.addEventListener('click', handleClickOutside, true);
    //     document.addEventListener('keydown', handleKeyPressEvent);
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPressEvent);
    //         document.removeEventListener('click', handleClickOutside, true);
    //     };
    // }, [activeIndex]);
    //
    // useEffect(() => {
    //
    //     const handleKeyPressEvent = (event) => {
    //         if (event.key === 'Enter') {
    //             clickBtn();
    //             console.log('2enter event');
    //         }
    //     };
    //     document.addEventListener('click', handleClickOutside, true);
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPressEvent);
    //     };
    // });

    const formActive = classNames({
        'header': true,
        'activeForm': active,
        'fullWidth' : false
    });
    const btnActive = classNames({
        'activeBtn': active,
        'searchBtn': true
    });
    const crossBtn = classNames({
        'activeBtn': true,
        'crossBtn': true
    });

    const placeholder = search ? search : 'Wyszukaj szkolenie';

    function clickBtn() {
        if (active) {
            setFilterdata([])
            setValueInput('')
        }
        setActive(!active)
    }
    function resetInput() {
        setFilterdata([])
        setValueInput('');
        setB2blink(root_link);
        setSearch(false);
    }

    function handleKeyPressSecond(e) {
        if (e === 'Enter') {
            //clickBtn();
            handleKeyPress(valueInput)
            window.location.href = root_link + '/?search-b2b=' + valueInput;
        }
    }


    function handleKeyPress(e) {
        let currentValue = e.toLowerCase();
        setValueInput(e)
        if (currentValue.length >= 3) {
            let filterPost = posts.filter(post => post.title.toLowerCase().indexOf(currentValue) > -1 || post.desc.toLowerCase().indexOf(currentValue) > -1 || post.content.toLowerCase().indexOf(currentValue) > -1)
            setFilterdata(filterPost);
            setB2blink(root_link + '/?search-b2b=' + currentValue);
        } else {
            setFilterdata([]);
        }
    }

    return (

        <div className="form" ref={ref}>
            <div className={formActive} ref={secondRef}>
                <button className={btnActive} onClick={() => clickBtn()}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.4844 16.2227L12.274 11.0114C13.1137 9.85754 13.5654 8.46687 13.564 7.0398C13.564 3.3046 10.525 0.265625 6.7898 0.265625C3.0546 0.265625 0.015625 3.3046 0.015625 7.0398C0.015625 10.775 3.0546 13.814 6.7898 13.814C8.21687 13.8154 9.60754 13.3637 10.7614 12.524L15.9727 17.7344L17.4844 16.2227ZM6.7898 11.6745C5.87303 11.6746 4.97683 11.4028 4.21452 10.8935C3.45222 10.3842 2.85806 9.66032 2.50719 8.81335C2.15632 7.96638 2.0645 7.03439 2.24333 6.13522C2.42216 5.23606 2.86362 4.41013 3.51188 3.76188C4.16013 3.11362 4.98606 2.67216 5.88522 2.49333C6.78439 2.3145 7.71638 2.40632 8.56335 2.75719C9.41032 3.10806 10.1342 3.70222 10.6435 4.46452C11.1528 5.22683 11.4246 6.12303 11.4245 7.0398C11.423 8.26855 10.9343 9.44656 10.0654 10.3154C9.19656 11.1843 8.01855 11.673 6.7898 11.6745Z"
                            fill="#9F9F9F"/>
                    </svg>
                </button>
                <input placeholder={placeholder} type='text' onChange={(e) => handleKeyPress(e.target.value)} onKeyDown={(e) => handleKeyPressSecond(e.key)} value={valueInput}></input>
                <button className={crossBtn} onClick={() => resetInput()}>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 1L1 18M1 1L18 18" stroke="#7C7C7C" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            {show_button && (
                <a className="redirectBtn" href={b2blink}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24 32L32 24M32 24L24 16M32 24H16M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z"
                            stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            )}

            {filterData.length > 0 && (
                <div className="listPostWrapper" style={{width: `${width}px`}}>
                    <div className="listPostHeader">
                        <p>Szkolenia</p>
                    </div>
                    <ul className='listPost'>
                        {filterData.map((data, index) => (
                            <li
                                key={index}
                                className={index === activeIndex ? 'active' : ''}
                            >
                                <a href={data.url}>{data.title}</a>
                            </li>
                        ))}
                    </ul>
                    {show_button && (
                        <div className="listPostFooter">
                            <a href={root_link}>Pokaż wszystkie szkolenia</a>
                        </div>
                    )}
                </div>
            )}

        </div>
    );
}

export default App;
