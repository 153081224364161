import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';


const element = document.getElementById('search_form_b2b');
if(element){
    const root_link = element.getAttribute('data-link');
    const root_endpoint = element.getAttribute('data-endpoint');
    const show_button = element.getAttribute('data-show-redirect');
    const search_b2b = element.getAttribute('data-search-b2b');

    const root = ReactDOM.createRoot(element);
    root.render(
        <React.StrictMode>
            <App
                root_link={root_link}
                root_endpoint={root_endpoint}
                show_button="true"
                search_b2b={search_b2b}/>
        </React.StrictMode>
    );
}


reportWebVitals();
